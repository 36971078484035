
import { defineComponent, onMounted } from "vue";
import KTTableWidgetStockCardDetail from "@/components/widgets/tables/WidgetStockCardDetail.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "daily-cash-flow",
  components: {
    KTTableWidgetStockCardDetail,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Stock Card Detail", ["Financial Statements","Stock Card"]);
    });
  },
});
